



































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
    apiGetCustomerInfo,
    apiCustomerEdit,
    apiCustomerAdd,
} from "@/api/shop";

import { debounce } from "@/utils/util";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
    components: { MaterialSelect },
})
export default class ListsDetail extends Vue {
    /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    setMealData: any = [];

    // 添加商城表单数据
    form: any = {
        bsn: "",
        company_address: "",
        company_bl_img: "",
        company_bl_no: "",
        company_name: "",
        contact_mobile: "",
        contact_name: "",
        kh_leibie: "",
        kh_laiyuan: "",
        kh_phone: "",
        yg_number : "",
        expires_time: "",
        remark: "",
        status: 1,
        password: "",
        checkPass: "",
        account: "",
    };

    // 表单校验
    rules = {
        bsn: [
            { required: true, message: "请输入客户平台编码", trigger: "blur" },
        ],
        company_name: [
            { required: true, message: "请输入客户名称", trigger: "blur" },
            {
                max: 20,
                message: "客户名称输入长度限制20个字符以内",
                trigger: "blur",
            },
        ],
        company_address: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
            {
                max: 50,
                message: "地址输入长度限制50个字符以内",
                trigger: "blur",
            },
        ],
        company_bl_no: [
            { required: true, message: "请输入证件号码", trigger: "blur" },
            { max: 24, message: "证件号码限制24个字符以内", trigger: "blur" },
        ],
        company_bl_img: [
            { required: true, message: "请上传证件", trigger: "blur" },
        ],
        contact_name: [
            { required: true, message: "请输入联系人名称", trigger: "blur" },
            {
                max: 20,
                message: "联系人名称输入长度限制20个字符以内",
                trigger: "blur",
            },
        ],
        contact_mobile: [
            { required: true, message: "请输入联系人手机号", trigger: "blur" },
            {
                validator: (rule: any, value: any, callback: any) => {
                    const regExp =
                        /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                    if (!regExp.test(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                },
                trigger: "change",
            },
        ],
        kh_phone: [
            { required: true, message: "请输入客户手机号", trigger: "blur" },
            {
                validator: (rule: any, value: any, callback: any) => {
                    const regExp =
                        /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                    if (!regExp.test(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                },
                trigger: "change",
            },
        ],
        expires_time: [
            { required: true, message: "请选择到期时间", trigger: "blur" },
        ],
        kh_leibie: [
            { required: true, message: "请选择客户类别", trigger: "blur" },
        ],
        kh_laiyuan: [
            { required: true, message: "请选择客户来源", trigger: "blur" },
        ],
        yg_number : [
            { required: true, message: "请选择员工人数", trigger: "blur" },
        ],
        account: [
            { required: true, message: "请输入集采账户", trigger: "blur" },
            {
                max: 32,
                message: "集采账户输入长度限制32个字符以内",
                trigger: "blur",
            },
        ],
        password: [
            {
                required: true,
                validator: (rule: any, value: any, callback: any) => {
                    if (value === "" && !this.identity) {
                        return callback();
                    }
                    if (value === "") {
                        callback(new Error("请输入密码"));
                    } else {
                        if (value.length < 6)
                            return callback(new Error("密码长度不得低于6位"));
                        if (value.length > 18)
                            return callback(new Error("密码长度不得高于18位"));
                        const regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
                        if (!regExp.test(value))
                            return callback(
                                new Error("密码至少要包含数字和字母")
                            );
                        if (this.form.checkPass !== "") {
                            (this.$refs.form as any).validateField("checkPass");
                        }
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
        checkPass: [
            {
                required: true,
                validator: (rule: any, value: any, callback: any) => {
                    if (
                        value === "" &&
                        this.form.password === "" &&
                        !this.identity
                    ) {
                        return callback();
                    }
                    if (value === "") {
                        callback(new Error("请再次输入密码"));
                    } else if (value !== this.form.password) {
                        callback(new Error("两次输入密码不一致!"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
        remark: [
            {
                max: 50,
                message: "备注输入长度限制50个字符以内",
                trigger: "blur",
            },
        ],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;

            if (!this.identity) {
                this.handleShopAdd();
            } else {
                this.handleCustomerEdit();
            }
        });
    }

    // 添加商城
    async handleShopAdd(): Promise<void> {
        await apiCustomerAdd({ ...this.form });
        setTimeout(() => this.$router.go(-1), 500);
    }

    // 编辑商城
    async handleCustomerEdit(): Promise<void> {
        /*if (this.form.password === "<>?L:{}0") {
      this.form.password = "";
      this.form.checkPass = "";
    }*/
        await apiCustomerEdit({ ...this.form, id: this.identity });
        setTimeout(() => this.$router.go(-1), 500);
    }

    // 获取详情
    async getCustomerFunc(): Promise<void> {
        const res: any = await apiGetCustomerInfo({
            id: this.identity as number,
        });
        Object.keys(res).map((key) => {
            this.$set(this.form, key, res[key]);
        });
        /*this.form.password = "<>?L:{}0";
    this.form.checkPass = "<>?L:{}0";*/
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;

        // 编辑模式：初始化数据
        this.identity = +query.id;
        if (+query.id) {
            this.rules.password[0].required = false;
            this.rules.checkPass[0].required = false;
            this.getCustomerFunc();
        }

        this.onSubmit = debounce(this.onSubmit, 300);
    }
    /** E Life Cycle **/
}
